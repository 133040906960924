import { ref, type MaybeRef } from 'vue';

import type { FetchGroupLoanApplicationsRansackFilters } from '@/api/groupLoanApplication/queries';

const GROUP_LOAN_APPLICATIONS_KEY = ['groupLoanApplications'];

function groupLoanApplicationKey(id: MaybeRef<number>) {
  return [...GROUP_LOAN_APPLICATIONS_KEY, ref(id)];
}

function groupLoanApplicationsKey(q?: FetchGroupLoanApplicationsRansackFilters) {
  return [...GROUP_LOAN_APPLICATIONS_KEY, { q }];
}

export { groupLoanApplicationKey, groupLoanApplicationsKey };
