<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { useField } from 'vee-validate';
import { ref, watch, onMounted, onUnmounted, toRef, computed } from 'vue';

import BaseIcon from '@/components/base-icon.vue';
import useFilteredAttrs from '@/composables/useFilteredAttrs';
import { COUNTRIES, type Country } from '@/utils/countries';

function findCountryCode(hint: string) {
  const code = hint.toUpperCase();
  const countryIndex = COUNTRIES.findIndex(country => country.key === code);
  if (countryIndex === -1) {
    return COUNTRIES[0];
  }

  return COUNTRIES[countryIndex];
}

function findCountryCodeFromPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return null;

  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  return COUNTRIES.find(country => numericPhoneNumber.startsWith(country.code));
}

function removeCountryCode(value: string, code: string) {
  if (value) {
    const countryCode = code;

    if (value.startsWith('+')) {
      return value.replace(`+${countryCode}`, '');
    } else if (
      value.startsWith(countryCode)) {
      return value.replace(countryCode, '');
    }
  }

  return value;
}

interface Props {
  label: string;
  name: string;
  description?: string;
  countryHint?: string;
  helperIcon?: string;
  helperTooltip?: string;
}
const props = withDefaults(defineProps<Props>(), {
  description: undefined,
  countryHint: 'mx',
  helperIcon: 'whatsapp',
  helperTooltip: 'Asegúrate de que el formato de Whatsapp comience con +52, no tenga espacio y tenga 10 dígitos',
});

const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
  setTouched,
} = useField<string>(toRef(props, 'name'));

const selectedCountry = ref(findCountryCodeFromPhoneNumber(inputValue.value) || findCountryCode(props.countryHint));
const visibleInputValue = ref(removeCountryCode(inputValue.value, selectedCountry.value.code));

const selectOpen = ref(false);

const toggleSelectButtonRef = ref<HTMLElement | null>(null);
const selectRef = ref<HTMLElement | null>(null);

function handleClickOutsideSelect(event: Event) {
  const target = event.target as HTMLInputElement;

  if (!selectRef.value?.contains(target) && !toggleSelectButtonRef.value?.contains(target)) {
    selectOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutsideSelect);
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutsideSelect);
});

function selectCountry(country: Country, shouldValidate: boolean = false) {
  selectedCountry.value = country;
  selectOpen.value = false;

  const valueWithCountryCode = removeCountryCode(inputValue.value, selectedCountry.value.code);
  handleChange(`+${country.code}${(valueWithCountryCode) || ''}`, shouldValidate);
}

watch(() => props.countryHint, (newVal) => {
  const countryCode = findCountryCode(newVal);
  selectCountry(countryCode);
});

const valueWithCountryCode = computed(() => `+${selectedCountry.value.code}${visibleInputValue.value}`);

function updateVisibleInputValue(event: Event) {
  setTouched(false);
  const target = event.target as HTMLInputElement;
  visibleInputValue.value = target.value;

  handleChange(valueWithCountryCode.value, true);
}

watch(inputValue, (newVal) => {
  if (newVal) {
    visibleInputValue.value = removeCountryCode(newVal, selectedCountry.value.code);
  } else {
    visibleInputValue.value = '';
  }
});

function handlePaste(event: ClipboardEvent) {
  event.preventDefault();
  const paste = event.clipboardData?.getData('text');
  const valueWithoutCountryCode = removeCountryCode(paste || '', selectedCountry.value.code)
    .replaceAll(' ', '');
  visibleInputValue.value = valueWithoutCountryCode;
  handleChange(valueWithCountryCode.value, true);
}

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

</script>

<template>
  <div :class="$attrs.class || 'flex flex-col items-start w-full'">
    <label
      v-if="label"
      :for="name"
      class="font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="relative mt-1.5 w-full">
      <div
        class="box-border flex w-full flex-row items-center overflow-hidden rounded-md border border-transparent bg-white px-1.5 shadow-sm ring-1 ring-inset transition duration-300 ease-in-out focus-within:ring-2 focus-within:ring-primary-600"
        :class="{
          'border-red-400': errorMessage,
          'ring-gray-300 hover:border-primary-500': !errorMessage,
        }"
      >
        <button
          ref="toggleSelectButtonRef"
          class="-m-1 mr-1 rounded-[4px] bg-primary-50 pr-1"
          type="button"
          @click="selectOpen = !selectOpen"
        >
          <div class="flex flex-row items-center px-2">
            <div
              v-if="selectedCountry"
              class="flex flex-row items-center gap-x-2"
            >
              <span class="text-xl">
                {{ selectedCountry.emoji }}
              </span>
              <span class="text-sm">
                +{{ selectedCountry.code }}
              </span>
            </div>
            <chevron-down-icon
              :class="[
                'ml-2 h-3 w-3 fill-current text-gray-700',
                { 'rotate-180': !selectOpen }
              ]"
            />
          </div>
        </button>
        <div
          v-if="selectOpen"
          ref="selectRef"
          class="absolute top-12 z-30 h-[84px] w-40 overflow-scroll rounded-md border border-primary-600 bg-white py-2 shadow sm:h-20 sm:w-40"
        >
          <button
            v-for="country in COUNTRIES"
            :key="country.name"
            class="flex w-full flex-row items-center gap-x-2 px-2 py-0.5 text-gray-700 hover:bg-primary-600 hover:text-white"
            type="button"
            @click="selectCountry(country, true)"
          >
            <span class="text-xl">
              {{ country.emoji }}
            </span>
            <span class="text-ellipsis whitespace-nowrap">
              {{ country.name }}
            </span>
            <span class="text-sm">
              +{{ country.code }}
            </span>
          </button>
        </div>
        <input
          :name="name"
          type="hidden"
          :value="inputValue"
          v-bind="filteredAttrs"
        >
        <input
          class="h-8 w-full border-none bg-transparent p-1 text-left text-gray-900 outline-none placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
          :value="visibleInputValue"
          @input="updateVisibleInputValue"
          @paste="handlePaste"
        >
        <base-icon
          v-if="helperIcon"
          v-tooltip="helperTooltip"
          :icon="helperIcon"
          class="mx-2 h-5 w-auto fill-current text-gray-400"
        />
      </div>
    </div>
    <span
      v-if="errorMessage"
      class="mt-1 text-xs text-red-500 sm:text-sm"
      :class="{
        'animate-bounce': meta.touched,
      }"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>
