import * as Sentry from '@sentry/vue';
import { MutationCache, QueryClient, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { es } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import 'trix/dist/trix.css';
import FloatingVue from 'floating-vue';
import { camelizeKeys } from 'humps';
import { createPinia } from 'pinia';
import posthog from 'posthog-js';
import Trix from 'trix';
import { createApp, provide, computed, onBeforeMount } from 'vue';
import { createI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';

import AppDownloadOverlay from '@/components/app-download-overlay.vue';
import BaseButton from '@/components/base-button.vue';
import BreakpointsHelper from '@/components/breakpoints-helper.vue';
import TheAppLayout from '@/components/the-app-layout.vue';
import TheCollectionLayout from '@/components/the-collection-layout.vue';
import TheCrmLayout from '@/components/the-crm-layout.vue';
import TheReviewsLayout from '@/components/the-reviews-layout.vue';
import TheSupportLayout from '@/components/the-support-layout.vue';
import useScreen from '@/composables/useScreen';
import ClickOutside from '@/directives/clickOutside';
import 'floating-vue/dist/style.css';
import '@/css/application.css';
import esLocales from '@/locales/es';
import useUserStore from '@/stores/user';
import {
  abbreviateNumber,
  formatNumber,
  toCurrency,
  formatDate,
  formatTime,
  formatRelativeDate,
} from '@/utils/filters';
import {
  landingOriginKey,
  wspSupportNumberKey,
  flashMessagesKey,
  currentUserKey,
  screenKey,
  mobileAppStoreUrlKey,
} from '@/utils/keys';
import '@/utils/yupConfig';
import storeUrl from '@/utils/storeUrl';
import CollectionGroupLoansIndex from '@/views/collection/group-loans/index.vue';
import CrmWhatsappConversationsIndex from '@/views/crm/whatsapp/conversations/index.vue';
import UnsignedContractsShow from '@/views/group-member-loan-applications/unsigned-contracts/show.vue';
import LandingIndex from '@/views/landing/index.vue';
import PromotersGroupLoanApplicationsIndex from '@/views/promoters/group-loan-applications/index.vue';
import PromotersGroupLoansIndex from '@/views/promoters/group-loans/index.vue';
import PromotersHomeIndex from '@/views/promoters/home/index.vue';
import ReviewsGroupLoanApplicationsIndex from '@/views/reviews/group-loan-applications/index.vue';
import ReviewsGroupLoanApplicationsShow from '@/views/reviews/group-loan-applications/show.vue';
import SupportGroupMemberLoanApplicationShow from '@/views/support/group-member-loan-applications/show.vue';
import UsersProfileShow from '@/views/users/profile/show.vue';
import UsersRegistrationsNew from '@/views/users/registrations/new.vue';
import UsersSessionsNew from '@/views/users/sessions/new.vue';

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  messages: { es: esLocales },
});

setDefaultOptions({ locale: es });

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: {},
    // eslint-disable-next-line max-statements
    setup() {
      const LANDING_DOMAIN = window.env.LANDING_DOMAIN;

      provide(
        landingOriginKey,
        (LANDING_DOMAIN && `https://${LANDING_DOMAIN}`) || window.location.origin,
      );
      provide(wspSupportNumberKey, window.env.WSP_SUPPORT_NUMBER);
      provide(flashMessagesKey, window.flashMessages);
      provide(mobileAppStoreUrlKey, storeUrl);

      const userStore = useUserStore();
      provide(currentUserKey, computed(() => userStore.currentUser));

      onBeforeMount(() => {
        if (window.currentUser) {
          userStore.setCurrentUser(window.currentUser);
          if (window.env.SENTRY_DSN && window.env.PRODUCTION) {
            Sentry.setUser({
              id: userStore.currentUser.id,
              phone: userStore.currentUser.phoneNumber,
              fullName: userStore.currentUser.fullName,
            });
          }

          if (window.env.POSTHOG_API_KEY && window.env.PRODUCTION) {
            posthog.identify(
              userStore.currentUser.id.toString(),
              {
                id: userStore.currentUser.id,
                phone: userStore.currentUser.phoneNumber,
                fullName: userStore.currentUser.fullName,
              },
            );
          }
        }
      });

      const { screen } = useScreen();
      provide(screenKey, screen);
    },
  });

  if (window.env.PRODUCTION) {
    if (window.env.SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: window.env.SENTRY_DSN,
        integrations: [
          new posthog.SentryIntegration(posthog, 'Reality', window.env.POSTHOG_PROJECT_ID),
        ],
      });
    }

    if (window.env.POSTHOG_API_KEY) {
      posthog.init(window.env.POSTHOG_API_KEY, {
        'session_recording': {
          maskAllInputs: true, // Important - this needs to be true for the below function to be called
          maskInputFn: (text, element) => {
            if (element?.attributes.type?.value === 'password') {
              return '*'.repeat(text.length);
            }

            return text;
          },
        },
      });
    }
  }

  app.config.globalProperties.$filters = {
    abbreviateNumber,
    formatNumber,
    camelizeKeys,
    toCurrency,
    formatDate,
    formatTime,
    formatRelativeDate,
  };

  // views
  app.component('LandingIndex', LandingIndex);
  app.component('UsersSessionsNew', UsersSessionsNew);
  app.component('UsersRegistrationsNew', UsersRegistrationsNew);
  app.component('ReviewsGroupLoanApplicationsShow', ReviewsGroupLoanApplicationsShow);
  app.component('ReviewsGroupLoanApplicationsIndex', ReviewsGroupLoanApplicationsIndex);
  app.component('SupportGroupMemberLoanApplicationShow', SupportGroupMemberLoanApplicationShow);
  app.component('UsersProfileShow', UsersProfileShow);
  app.component('CrmWhatsappConversationsIndex', CrmWhatsappConversationsIndex);
  app.component('CollectionGroupLoansIndex', CollectionGroupLoansIndex);
  app.component('PromotersGroupLoanApplicationsIndex', PromotersGroupLoanApplicationsIndex);
  app.component('PromotersHomeIndex', PromotersHomeIndex);
  app.component('PromotersGroupLoansIndex', PromotersGroupLoansIndex);
  app.component('UnsignedContractsShow', UnsignedContractsShow);

  // components
  app.component('BreakpointsHelper', BreakpointsHelper);
  app.component('TheAppLayout', TheAppLayout);
  app.component('TheSupportLayout', TheSupportLayout);
  app.component('TheReviewsLayout', TheReviewsLayout);
  app.component('TheCrmLayout', TheCrmLayout);
  app.component('TheCollectionLayout', TheCollectionLayout);
  app.component('BaseButton', BaseButton);
  app.component('AppDownloadOverlay', AppDownloadOverlay);

  app.component('InlineSvg', InlineSvg);
  app.component('VueDatePicker', VueDatePicker);

  app.use(FloatingVue);
  app.use(Trix);

  app.directive('click-outside', ClickOutside);

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSuccess: (data, _variables, _context, mutation) => {
        if (!!mutation.options.mutationKey) {
          if (data) queryClient.setQueryData(mutation.options.mutationKey, data);
          else queryClient.invalidateQueries({ queryKey: mutation.options.mutationKey });
        }
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClient,
  };

  app.use(VueQueryPlugin, vueQueryPluginOptions);

  app.use(i18n);

  const pinia = createPinia();
  app.use(pinia);

  app.mount('#vue-app');

  return app;
});
