<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { UserGroupIcon } from '@heroicons/vue/24/outline';
import { format, formatDistance, parseISO } from 'date-fns';
import { ref, computed, watch, inject, nextTick } from 'vue';

import { useUpdatePromoterPresidentInvite } from '@/api/promoterPresidentInvite/mutations';
import { useFetchPromoterPresidentInvites } from '@/api/promoterPresidentInvite/queries';
import BaseBackButton from '@/components/base-back-button.vue';
import BaseBadge from '@/components/base-badge.vue';
import BaseCheckbox from '@/components/base-checkbox.vue';
import BaseEmptyState from '@/components/base-empty-state.vue';
import GroupPromoterInputFormModal from '@/components/promoters/group-promoter-input-form-modal.vue';
import { currentUserKey } from '@/utils/keys';

const props = defineProps({
  groupLoanApplications: { type: Array, default: () => [] },
  subordinate: { type: Object, default: null },
});

const currentUser = inject(currentUserKey);

const formattedGroupLoanApplications = computed(() => props.groupLoanApplications.map(_app => {
  const app = { ..._app };

  if (app.submittedAt) {
    const formattedDate = format(parseISO(app.submittedAt), 'dd-MM-yyyy');
    const timeAgo = formatDistance(parseISO(app.submittedAt), new Date(), { addSuffix: true });
    app.formattedSubmittedAt = `${formattedDate} (${timeAgo})`;
  }

  return app;
}));

function getGroupBadgeColor(application) {
  const colors = {
    'inviting': 'yellow',
    'draft': 'blue',
    'application_in_review': 'green',
    'in_kyc': 'pink',
    'kyc_in_review': 'pink',
    'contracts_signature_pending': 'pink',
    'contracts_signed': 'green',
    'disbursed': 'green',
  };

  return colors[application.aasmState] || 'gray';
}

function getMemberBadgeColor(mla) {
  const colors = {
    'draft': 'yellow',
    'submitted': 'blue',
    'rejected': 'red',
  };

  return colors[mla.aasmState];
}

const queryParams = new URLSearchParams(window.location.search);
const aasmState = queryParams.get('aasm_state');
const showDisbursed = ref(aasmState === 'disbursed');
const formRef = ref(null);
const selectedGroupPromoterInputInviteeId = ref(null);
const showGroupPromoterInputForm = ref(false);
const formAction = computed(() => {
  const baseUrl = window.location.pathname;

  return showDisbursed.value ? `${baseUrl}?aasm_state=disbursed` : baseUrl;
});

const fetchPromoterPresidentInvitesQ = useFetchPromoterPresidentInvites(
  {
    inviteeId: selectedGroupPromoterInputInviteeId,
    onError: () => {
      uiStore.toast({
        message: e.response?.data?.detail || 'Hubo un error al obtener la información',
        type: 'error',
        position: 'top',
      });
    },
  },
);

const promoterPresidentInviteId = computed(() => fetchPromoterPresidentInvitesQ.data?.[0]?.id);

const updatePromoterPresidentInputMutation = useUpdatePromoterPresidentInvite({
  id: promoterPresidentInviteId,
  onSuccess: () => {
    uiStore.toast({
      message: 'Información actualizada',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la información',
      type: 'error',
      position: 'top',
    });
  },
});

function handleOpenGroupPromoterForm(groupLoanApplication) {
  const inviteeId = groupLoanApplication.memberLoanApplications.find(
    (mla) => mla.groupMember.president,
  )?.groupMember?.userId;

  selectedGroupPromoterInputInviteeId.value = inviteeId;
  showGroupPromoterInputForm.value = true;
}

function handleModifyGroupPromoter(values) {
  updatePromoterPresidentInputMutation.mutate(values);
  showGroupPromoterInputForm.value = false;
}

watch(showDisbursed, () => {
  nextTick(() => {
    formRef.value.innerFormRef.submit();
  });
});

</script>

<template>
  <div class="flex w-full max-w-md flex-col">
    <base-back-button
      :href="subordinate ? `/promoters/${subordinate.id}/home` : `/promoters/${currentUser.id}/home`"
    />
    <h1 class="text-lg font-bold text-gray-600">
      Solicitudes pendientes
    </h1>
    <v-form-native-wrapper
      ref="formRef"
      method="GET"
      :action="formAction"
      class="mt-4 flex flex-row items-center justify-between gap-x-4"
    >
      <input
        v-if="showDisbursed"
        type="hidden"
        name="aasm_state"
        value="disbursed"
      >
      <span class="text-xs">
        Mostrar solicitudes de grupos desembolsados
      </span>
      <base-checkbox
        v-model="showDisbursed"
        name="showDisbursed"
      />
    </v-form-native-wrapper>
    <group-promoter-input-form-modal
      :show="showGroupPromoterInputForm"
      :previous-promoter-president-invite="fetchPromoterPresidentInvitesQ.data?.[0]"
      @close="showGroupPromoterInputForm = false"
      @submit="handleModifyGroupPromoter"
    />
    <ul
      v-if="formattedGroupLoanApplications.length > 0"
      class="mt-8 flex w-full flex-col gap-y-8"
    >
      <li
        v-for="groupLoanApplication in formattedGroupLoanApplications"
        :key="groupLoanApplication.id"
        class="rounded-lg bg-white"
      >
        <Disclosure v-slot="{ open }">
          <DisclosureButton class="flex w-full justify-between gap-x-4 rounded-md px-4 py-2 text-left text-sm">
            <div class="flex flex-row items-center gap-x-2">
              <h2 class="text-sm sm:text-base">
                {{ groupLoanApplication.group.name }}
              </h2>
              <base-badge
                :color="getGroupBadgeColor(groupLoanApplication)"
                :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
                size="xs"
              />
            </div>
            <ChevronUpIcon
              :class="open ? 'rotate-180 transform' : ''"
              class="h-5 w-5 text-purple-500"
            />
          </DisclosureButton>
          <DisclosurePanel>
            <div class="flex flex-col gap-y-4 px-4 py-2">
              <base-button
                variant="white"
                size="sm"
                @click="() => handleOpenGroupPromoterForm(groupLoanApplication)"
              >
                Modificar información del grupo
              </base-button>
              <div class="flex flex-col gap-y-4 rounded-md bg-white px-4 py-2">
                <span
                  v-if="groupLoanApplication.formattedSubmittedAt"
                  class="mt-2 text-xs text-gray-600"
                >
                  Enviada el {{ groupLoanApplication.formattedSubmittedAt }}
                </span>
                <div class="grid grid-cols-2 gap-x-4">
                  <span class="text-xs">
                    💰 Monto solicitado
                  </span>
                  <span class="text-xs">
                    {{ $filters.toCurrency(groupLoanApplication.totalRequestedAmount) }}
                  </span>
                </div>
                <div
                  v-if="!['inviting', 'draft'].includes(groupLoanApplication.aasmState)"
                  class="grid grid-cols-2 gap-x-4"
                >
                  <span class="text-xs">
                    💰 Monto aceptado
                  </span>
                  <span class="text-xs">
                    {{ $filters.toCurrency(groupLoanApplication.totalAcceptedAmount) }}
                  </span>
                </div>
                <div class="grid grid-cols-2 gap-x-4">
                  <div class="inline-flex items-center gap-x-1 text-xs">
                    <UserGroupIcon class="h-3.5 w-3.5 shrink-0" />
                    <span>
                      Integrantes
                    </span>
                  </div>
                  <span class="text-xs">
                    {{ groupLoanApplication.memberLoanApplications.length }}
                  </span>
                </div>
              </div>
              <div class="mt-4 flex flex-col gap-y-6">
                <ul
                  v-for="gmla in groupLoanApplication.memberLoanApplications"
                  :key="gmla.id"
                  class="flex flex-col gap-y-1 text-xs text-gray-700"
                >
                  <span class="font-semibold text-gray-600">
                    {{ gmla.groupMember.user.fullName }}
                  </span>
                  <li class="grid grid-cols-2 items-center gap-x-4">
                    <span>
                      Estado
                    </span>
                    <base-badge
                      v-if="gmla.preApproved"
                      color="green"
                      label="Aprobada"
                      size="xs"
                      class="mr-auto"
                    />
                    <base-badge
                      v-else
                      :color="getMemberBadgeColor(gmla)"
                      :label="$t(`groupMemberLoanApplication.aasmState.${gmla.aasmState}`)"
                      size="xs"
                      class="mr-auto"
                    />
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Monto solicitado
                    </span>
                    <span>
                      {{ gmla.amount ? $filters.toCurrency(gmla.amount) : '-' }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Monto aceptado
                    </span>
                    <span v-if="gmla.preApproved">
                      {{ $filters.toCurrency(gmla.acceptedAmount) }}
                    </span>
                    <span v-else>
                      En revisión
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Pago por mil
                    </span>
                    <span v-if="gmla.preApproved">
                      {{ $filters.toCurrency(gmla.costPerThousand) }}
                    </span>
                    <span v-else>
                      En revisión
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Whatsapp
                    </span>
                    <span>
                      {{ gmla.groupMember.user.phoneNumber }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Domicilio
                    </span>
                    <span>
                      {{ gmla.groupMember.user.address }}
                    </span>
                  </li>
                  <li class="grid grid-cols-2 gap-x-4">
                    <span>
                      Buró de crédito
                    </span>
                    <div>
                      <a
                        v-if="gmla.creditBureauReportUrl"
                        :href="gmla.creditBureauReportUrl"
                        class="cursor-pointer self-start text-blue-500 underline"
                        target="_blank"
                      >
                        Descargar
                      </a>
                      <span v-else>
                        -
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </li>
    </ul>
    <base-empty-state
      v-else
      text="No hay solicitudes pendientes"
      class="mt-10"
    />
  </div>
</template>
