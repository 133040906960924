<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const emit = defineEmits(['accept', 'close']);
defineProps({
  show: { type: Boolean, required: true },
  title: { type: String, default: null },
  body: { type: String, default: null },
  acceptButtonText: { type: String, default: null },
  acceptButtonVariant: { type: String, default: 'primary' },
  cancelButtonText: { type: String, default: null },
  hideActions: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  closeOnOutsideClick: { type: Boolean, default: true },
});

function accept() {
  emit('accept');
}

function cancel() {
  emit('close');
}
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <TransitionRoot
    as="template"
    :show="show"
  >
    <Dialog
      as="div"
      class="relative z-30"
      @close="closeOnOutsideClick && $emit('close')"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-30 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full overflow-hidden rounded-2xl bg-white
                      px-4 pb-4 pt-5 text-left shadow-xl transition-all
                      sm:my-8 sm:w-full sm:max-w-lg sm:p-6 lg:max-w-xl xl:max-w-2xl"
            >
              <button
                class="absolute right-3 top-3 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                @click="$emit('close')"
              >
                <XMarkIcon class="h-5 w-5" />
              </button>
              <div class="mt-4 flex w-full flex-col items-center">
                <div class="flex w-full flex-col text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900"
                  >
                    <slot name="title">
                      {{ title }}
                    </slot>
                  </DialogTitle>
                  <slot name="body">
                    <p
                      v-if="body"
                      class="mt-12 text-sm text-gray-500"
                      v-html="body"
                    />
                  </slot>
                </div>
              </div>
              <div
                v-if="!hideActions"
                class="mt-12"
              >
                <slot name="actions">
                  <div
                    :class="[
                      'mt-5 flex flex-col gap-y-2 sm:mt-6 sm:gap-3',
                      { 'sm:flex-row': cancelButtonText }
                    ]"
                  >
                    <base-button
                      type="button"
                      class="w-full"
                      :text="acceptButtonText"
                      :loading="loading"
                      :variant="acceptButtonVariant"
                      @click="accept"
                    />
                    <base-button
                      v-if="cancelButtonText"
                      type="button"
                      variant="white"
                      :text="cancelButtonText"
                      class="w-full"
                      @click="cancel"
                    />
                  </div>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

