import html2canvas from 'html2canvas';

import { type GroupLoanApplication } from '@/api/groupLoanApplication';

function createTextContainer(groupLoanApplication: GroupLoanApplication) {
  const textContainer = document.createElement('div');

  const texts = [`Grupo: ${groupLoanApplication.group.name}`];

  if (groupLoanApplication.group.promoter) {
    texts.push(`Asesor: ${groupLoanApplication.group.promoter.fullName}`);
  }

  texts.forEach((text) => {
    const textElement = document.createElement('p');
    textElement.innerText = text;
    textContainer.appendChild(textElement);
  });

  return textContainer;
}

function createLogoElement() {
  const imgUrl = new URL('../../assets/images/logo.svg', import.meta.url).href;

  const logoElement = document.createElement('img');
  logoElement.src = imgUrl;
  logoElement.style.cssText = 'height: 40px; width: auto; color: black; align-self: start;';

  return logoElement;
}

function createParentElement() {
  const parentElement = document.createElement('div');

  parentElement.style.cssText = (
    'display: flex; flex-direction: column; justify-items: center; row-gap: 1.5rem; padding: 5rem;  width: 850px;'
  );

  return parentElement;
}

function createTable() {
  const tableElement = document.querySelector('table');
  if (!tableElement) {
    throw new Error('Table element not found');
  }

  const tableElementCopy = tableElement.cloneNode(true) as HTMLElement;
  tableElementCopy.style.alignSelf = 'center';

  return tableElementCopy;
}

export default function copyTableAsJpg(groupLoanApplication: GroupLoanApplication) {
  const tableElement = createTable();

  const parentElement = createParentElement();
  const logoElement = createLogoElement();
  const textContainer = createTextContainer(groupLoanApplication);

  [logoElement, textContainer, tableElement].forEach((element) => parentElement.append(element));
  document.body.appendChild(parentElement);

  html2canvas(parentElement, { scale: 3 }).then((canvas) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        throw new Error('Blob could not be generated');
      }

      navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
    });
  });
}
