import type { GroupMemberLoanApplication, Form } from '@/api/groupMemberLoanApplication';
import { api } from '@/api/index';
import type { Reference } from '@/api/reference';
import addToFormData from '@/utils/addToFormData';

interface SubmitBody {
  form: {
    idFront: File | null;
    sections: Form['data']['sections'];
  };
}
interface KycUploadBody {
  idFront: File | null;
  idBack: File | null;
  idSelfie: File | null;
  addressProof: File | null;
  references: Array<Pick<Reference, 'name' | 'phoneNumber' | 'relationship'>>;
}

function submit(groupMemberLoanApplicationId: number, body: SubmitBody) {
  const path = `/api/internal/support/group_member_loan_applications/${groupMemberLoanApplicationId}/submissions`;

  const formData = new FormData();
  addToFormData(formData, body, '');

  return api<{ groupMemberLoanApplication: GroupMemberLoanApplication }>({
    method: 'post',
    url: path,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
}

function uploadKyc(groupMemberLoanApplicationId: number, body: KycUploadBody) {
  const path = `/api/internal/support/group_member_loan_applications/${groupMemberLoanApplicationId}/kyc_submissions`;

  const formData = new FormData();
  addToFormData(formData, body, 'group_member_loan_application');

  return api({
    method: 'post',
    url: path,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
}

function generateSigningUrl(groupMemberLoanApplicationId: number) {
  const path = `/api/internal/support/group_member_loan_applications/${groupMemberLoanApplicationId}/signing_urls`;

  return api<string>({
    method: 'post',
    url: path,
  });
}

export type { SubmitBody, KycUploadBody };

export { submit, uploadKyc, generateSigningUrl };
