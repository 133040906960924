<script setup lang="ts">
import { format, formatDistance, parseISO } from 'date-fns';
import { computed } from 'vue';

import type { GroupLoanApplication } from '@/api/groupLoanApplication';
import BaseBadge from '@/components/base-badge.vue';
import { groupLoanApplicationBadgeColor } from '@/utils/states';

const CREATED_AT_AASM_STATES = ['draft', 'inviting'];
const SUBMITTED_AT_AASM_STATES = ['application_in_review', 'in_kyc', 'kyc_in_review', 'contracts_signature_pending'];
const MEETING_AT_AASM_STATES = ['contracts_signed', 'disbursed'];

interface Props {
  groupLoanApplication: GroupLoanApplication;
  displayStatusBadge?: boolean;
}

const props = defineProps<Props>();

const fieldText = computed(() => {
  if (CREATED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Creada';
  } else if (SUBMITTED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Enviada';
  } else if (MEETING_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return 'Reunidos';
  }

  return 'Actualizada';
});
const dateToDisplay = computed(() => {
  if (CREATED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.createdAt;
  } else if (SUBMITTED_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.submittedAt;
  } else if (MEETING_AT_AASM_STATES.includes(props.groupLoanApplication.aasmState)) {
    return props.groupLoanApplication.meetingAt;
  }

  return props.groupLoanApplication.updatedAt;
});
const formattedDate = computed(() => (
  dateToDisplay.value ? `(${format(parseISO(dateToDisplay.value), 'dd-MM-yyyy')})` : ''
));
const formattedTimeAgo = computed(() => (
  dateToDisplay.value ? formatDistance(parseISO(dateToDisplay.value), new Date(), { addSuffix: true }) : ''),
);
</script>

<template>
  <a
    class="flex w-64 cursor-pointer flex-col gap-y-4 rounded-md bg-white p-2 hover:bg-gray-200"
    :href="`/reviews/group_loan_applications/${groupLoanApplication.id}`"
  >
    <div class="flex justify-between">
      <h4 class="font-semibold">
        {{ groupLoanApplication.group.name }}
      </h4>
      <base-badge
        v-if="displayStatusBadge"
        :color="groupLoanApplicationBadgeColor(groupLoanApplication.aasmState)"
        :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
        size="xs"
      />
    </div>
    <p class="text-xs text-gray-400">
      {{ fieldText }} {{ formattedTimeAgo }} {{ formattedDate }}
    </p>
    <div className="flex gap-x-4 text-xs text-gray-600">
      <p
        v-if="groupLoanApplication.group.promoter"
        class="text-black"
      >
        {{ groupLoanApplication.group.promoter.firstName }} {{ groupLoanApplication.group.promoter.paternalSurname }}
      </p>
      <p>💸 {{ $filters.toCurrency(groupLoanApplication.totalRequestedAmount) }}</p>
      <p>👥 {{ groupLoanApplication.group.membersAndInvitationsCount }}</p>
    </div>
  </a>
</template>
