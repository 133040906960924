<script setup lang="ts">
import { useField } from 'vee-validate';
import { toRef } from 'vue';

import useFilteredAttrs from '@/composables/useFilteredAttrs';

interface Props {
  label?: string;
  description?: string;
  name: string;
  options: { label: string; value: string | number }[];
  selected?: string | number;
  size?: 'sm' | 'md';
  withUndefinedValue?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  description: '',
  selected: '',
  value: '',
  size: 'md',
  withUndefinedValue: true,
});

const filteredAttrs = useFilteredAttrs({ excluded: ['class'] });

const {
  value: inputValue,
  errorMessage,
  handleChange,
  meta,
  setTouched,
} = useField(toRef(props, 'name'));

</script>

<template>
  <div :class="$attrs.class">
    <label
      v-if="label"
      :for="name"
      class="font-medium leading-6 text-gray-900"
    >
      {{ label }}
    </label>
    <p
      v-if="description"
      class="mt-1.5 text-xs font-light text-gray-700"
    >
      {{ description }}
    </p>
    <div class="mt-2 flex flex-col">
      <select
        v-bind="filteredAttrs"
        :name="name"
        :value="inputValue"
        :class="[
          `block w-full rounded-md border border-transparent
            pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 transition duration-300 ease-in-out
            hover:border-purple-500 focus:ring-2 focus:ring-primary-600 sm:leading-6`,
          {
            'py-1 pl-1.5 text-xs': size === 'sm',
            'py-1.5 pl-3 text-sm': size === 'md',
          }
        ]"
        @input="handleChange"
        @focus="setTouched(false)"
      >
        <option
          v-for="option in (withUndefinedValue ? [{ label: 'Selecciona una opción', value: '' }, ...options] : options)"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <span
        v-if="errorMessage"
        class="mt-1 text-xs text-red-500 sm:text-sm"
        :class="{
          'animate-bounce': meta.touched,
        }"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>
