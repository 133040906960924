<script setup>
import { ref, onMounted } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline';
import { useEventBus } from '@vueuse/core';

const faqs = [
  {
    question: 'Ya tengo un grupo, ¿Puedo traerlo a Grupalia?',
    answer: '¡Sí! De hecho, si traes tu grupo de otra financiera o banco y comprueban ' +
      'que llevan varios ciclos juntos, podrán obtener un monto mayor, mejorar su tasa y ' +
      'obtener beneficios especiales.',
  },
  {
    question: '¿Qué tasa de interés cobran?',
    answer: [
      'Nuestras tasas son fijas y no cobramos ningún tipo de comisión por apertura, base o ahorro ' +
      'inicial. La tasa final se determina con un análisis con base al historial crediticio y la ' +
      'experiencia del grupo trabajando con créditos.',

      'Nuestro “costo por mil pesos” es de $72.49 pesos en promedio, sin embargo, dependiendo ' +
      'del perfil crediticio del grupo y cliente este puede ser desde $68.6 pesos hasta $77.5 pesos.',

      'Nuestra Tasa de interés anual fija en plazo semanal promedio es del 81.15% antes de impuestos. ' +
      'El CAT promedio a 16 semanas es del 123.7% sin IVA. Cálculo + realizado el 10 de ' +
      'Noviembre del 2023.',
    ],

  },
  {
    question: '¿A qué plazos puedo sacar mi crédito?',
    answer: 'Nuestros plazos son por semanas, y pueden ser desde 16 semanas ' +
      '(4 meses) a 20 semanas (5 meses). El plazo final será determinado con base a ' +
      'la solicitud de tu crédito. ',
  },
  {
    question: '¿Dónde puedo cobrar o recibir mi crédito grupal?',
    answer: 'Puedes recibir tu préstamo en una cuenta bancaria y utilizarlo ' +
      'como quieras o retirarlo en efectivo en algún cajero o sucursal (pueden aplicar comisiones). ',
  },
  {
    question: '¿Qué requisitos piden?',
    answer: [
      'Tener un negocio o querer empezar uno',
      'Tener o hacer un grupo de al menos 6 personas (pueden ser hombres y mujeres)',
      'Credencial para votar (INE) vigente',
      'Comprobante de domicilio original (vigencia de 3 meses)',
    ],
  },
  {
    question: '¿Cómo se hacen los pagos del crédito?',
    answer: 'Efectivo o transferencias bancarias.',
  },
  {
    question: '¿Puedo liquidar antes mi crédito?',
    answer: 'Sí puedes liquidar antes tu crédito, sin embargo, podrás ' +
      'renovar tu crédito solo hasta que acabe el ciclo de tu grupo.',
  },
  {
    question: '¿Qué beneficios obtengo al renovar?',
    answer: 'Al renovar, si su grupo cumplió puntualmente con sus ' +
      'pagos, podrás obtener un monto mayor y sujeto a un nuevo análisis ' +
      'crediticio, pagar menos intereses. ',
  },
  {
    question: '¿Grupalia es de confianza?',
    answer: [
      'Sí, somos una empresa legalmente constituida, que no pide ningún ' +
        'tipo de depósito, comisión o enganche para poder recibir un ' +
        'crédito. La información de tu solicitud se encuentra encriptada ' +
        'con los mayores estándares de seguridad bancarios y tecnológicos, ' +
        'por lo que solo es analizada por nuestros sistemas para aprobar los créditos.',

      'Contamos como aliados a Buró de Crédito, Círculo de Crédito, estamos ' +
        ' ademas estamos sujetos a lo dispuesto por la PROFECO y contamos ' +
        ' con el respaldo de inversionistas institucionales. ',
    ],
  },
];

const elementToScrollTo = ref(null);
const scrollEventBus = useEventBus('scroll');

onMounted(() => {
  scrollEventBus.on((event) => {
    if (elementToScrollTo.value && event === 'faqs') {
      elementToScrollTo.value.scrollIntoView({ behavior: 'smooth' });
    }
  });
});
</script>

<template>
  <div
    ref="elementToScrollTo"
    class="bg-white"
  >
    <div class="mx-auto max-w-7xl px-4 sm:py-32 md:px-12 lg:py-40">
      <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 class="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900 sm:text-4xl">
          Preguntas frecuentes
        </h2>
        <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure
            v-for="faq in faqs"
            :key="faq.question"
            v-slot="{ open }"
            as="div"
            class="pt-6"
          >
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                <span class="text-base font-semibold leading-7 text-primary-600">
                  {{ faq.question }}
                </span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon
                    v-if="!open"
                    class="h-6 w-6 text-primary-600"
                    aria-hidden="true"
                  />
                  <MinusSmallIcon
                    v-else
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel
              as="dd"
              class="mt-2 pr-12"
            >
              <p
                v-if="typeof faq.answer === 'string'"
                class="text-base leading-7 text-gray-600"
              >
                {{ faq.answer }}
              </p>
              <ul
                v-else
                class="ml-4 list-disc space-y-4 pt-4"
              >
                <li
                  v-for="answer in faq.answer"
                  :key="answer"
                  class="text-base leading-7 text-gray-600"
                >
                  {{ answer }}
                </li>
              </ul>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>
