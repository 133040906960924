import { useInfiniteQuery, type UseQueryOptions } from '@tanstack/vue-query';
import { unref, toRef, type MaybeRef } from 'vue';

import type { ApiError } from '@/api';
import {
  getAll, get, type GroupLoanApplication, type FetchGroupLoanApplicationsRansackParams,
} from '@/api/groupLoanApplication';
import { groupLoanApplicationKey, groupLoanApplicationsKey } from '@/api/queryKeys';
import useReactiveQuery from '@/composables/useReactiveQuery';

type FetchGroupLoanApplicationComposableArgs = {
  id: MaybeRef<number>
} & UseQueryOptions<GroupLoanApplication, ApiError>;

type FetchGroupLoanApplicationsResponse = {
 nextCursor: number | undefined;
 groupLoanApplications: GroupLoanApplication[]
}

function useFetchGroupLoanApplications(filters: FetchGroupLoanApplicationsRansackParams = {}) {
  return useInfiniteQuery<FetchGroupLoanApplicationsResponse, ApiError>({
    queryKey: groupLoanApplicationsKey(filters),
    queryFn: ({ pageParam }) => getAll({ q: filters, page: { number: pageParam as number } })
      .then((response) => response.data),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: 1,
  });
}

function useFetchGroupLoanApplication({
  id, ...options
}: FetchGroupLoanApplicationComposableArgs) {
  return useReactiveQuery({
    queryKey: groupLoanApplicationKey(toRef(id)),
    queryFn: () => get(unref(id)).then(response => response.data.groupLoanApplication),
    ...options,
  });
}

export { useFetchGroupLoanApplications, useFetchGroupLoanApplication };
export type { FetchGroupLoanApplicationsResponse };
