import type { CCLoan } from '@/api/groupMemberLoanApplication';

type SortableCCLoanColumn = 'apertura' | 'vencido' | 'max' | 'cierre';

const ACCOUNT_STATUS_STATES = {
  current: 1,
  overdue: 2,
  closed: 3,
};

function getAccountStatus(row: CCLoan) {
  if (row.fechaCierreCuenta) {
    return ACCOUNT_STATUS_STATES.closed;
  }

  if (row.saldoVencido > 0) {
      return ACCOUNT_STATUS_STATES.overdue;
  }

  return ACCOUNT_STATUS_STATES.current;
}

function sortCCLoans(
  loans: CCLoan[],
  column: SortableCCLoanColumn,
  sortAsc = true,
) {
  // eslint-disable-next-line complexity
  loans.sort((a, b) => {
    let aValue: number | Date = 0;
    let bValue: number | Date = 0;

    switch (column) {
    case 'apertura':
      aValue = new Date(a.fechaAperturaCuenta).getTime();
      bValue = new Date(b.fechaAperturaCuenta).getTime();
      break;
    case 'vencido':
      aValue = a.saldoVencido;
      bValue = b.saldoVencido;
      break;
    case 'max':
      aValue = a.creditoMaximo;
      bValue = b.creditoMaximo;
      break;
    case 'cierre':
      aValue = a.fechaCierreCuenta ? new Date(a.fechaCierreCuenta).getTime() : 0;
      bValue = b.fechaCierreCuenta ? new Date(b.fechaCierreCuenta).getTime() : 0;
      break;
    }

    if (sortAsc) {
      return aValue < bValue ? 1 : -1;
    }

    return aValue > bValue ? 1 : -1;
  });
}

export type { SortableCCLoanColumn };

export { sortCCLoans, getAccountStatus, ACCOUNT_STATUS_STATES };
