<script setup lang="ts">
import { watch, ref, reactive } from 'vue';
import { object, bool, string } from 'yup';

import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import BaseCheckbox from '@/components/base-checkbox.vue';
import BaseFileInput from '@/components/base-file-input.vue';
import BaseFormSection from '@/components/base-form-section.vue';
import BaseLongTextInput from '@/components/base-long-text-input.vue';
import BaseModal from '@/components/base-modal.vue';
import BaseNativeSelect from '@/components/base-native-select.vue';
import BaseShortTextInput from '@/components/base-short-text-input.vue';

const INITIAL_VALUES = {
  knownSince: undefined,
  knownFrom: undefined,
  consolidated: undefined,
  previousCreditInstitution: undefined,
  additionalInformation: undefined,
  paymentSheetFile: undefined,
};

const emit = defineEmits(['close', 'submit']);

type FormValues = {
  knownSince?: string;
  knownFrom?: string;
  consolidated?: boolean | null;
  additionalInformation?: string;
  previousCreditInstitution?: string;
  paymentSheetFile?: File;
}

interface Props {
  show: boolean;
  previousPromoterPresidentInvite?: PromoterPresidentInvite;
}

const props = defineProps<Props>();

const validationSchema = object({
  knownSince: string(),
  knownFrom: string(),
  consolidated: bool(),
  additionalInformation: string(),
  previousCreditInstitution: string(),
});

const keyRef = ref(0);
const isLoading = ref(false);
const paymentSheetUrl = ref<string | undefined | null>(null);
const formValues = reactive<FormValues>({ ...INITIAL_VALUES });

function handleSubmit() {
  if (!formValues.consolidated) {
    formValues.previousCreditInstitution = undefined;
    formValues.paymentSheetFile = undefined;
  }

  emit('submit', { ...formValues });
  Object.assign(formValues, { ...INITIAL_VALUES });
}

watch(
  () => [props.previousPromoterPresidentInvite, props.show],
  () => {
    if (props.previousPromoterPresidentInvite) {
      const { paymentSheet, ...previousFormValues } = props.previousPromoterPresidentInvite;
      Object.assign(formValues, previousFormValues);
      paymentSheetUrl.value = paymentSheet?.fileUrl;
      keyRef.value++;
    }
  },
);
</script>

<template>
  <base-modal
    :show="show"
    cancel-button-text="Rellenar más tarde"
    accept-button-text="Guardar información"
    :loading="isLoading"
    @close="$emit('close')"
    @accept="handleSubmit"
  >
    <template #body>
      <v-form
        :key="keyRef"
        class="flex flex-col space-y-5"
        :validation-schema="validationSchema"
      >
        <base-form-section
          title="Cuéntanos más"
        >
          <base-native-select
            v-model="formValues.knownSince"
            label="¿Hace cuánto conoces al grupo?"
            name="knownSince"
            :options="[
              { value: 'less_than_3_months', label: '3 meses o menos' },
              { value: 'less_than_6_months', label: '6 meses o menos' },
              { value: 'less_than_1_year', label: '1 año o menos' },
              { value: 'more_than_1_year', label: 'Más de 1 año' },
            ]"
          />
          <base-native-select
            v-model="formValues.knownFrom"
            label="¿Cómo conoces al grupo?"
            name="knownFrom"
            :options="[
              { value: 'recommended_by_current_client', label: 'Me lo recomendó un cliente actual' },
              { value: 'recommended_by_someone', label: 'Me lo recomendó otra persona' },
              { value: 'contacted_by_someone', label: 'Me contactaron' },
              { value: 'worked_with_them_before', label: 'He trabajado con ellas antes' },
            ]"
          />
          <base-checkbox
            v-model="formValues.consolidated"
            label="¿Es un grupo consolidado?"
            name="consolidated"
          />
          <div
            v-if="formValues.consolidated"
            class="flex flex-col space-y-5"
          >
            <base-short-text-input
              v-model="formValues.previousCreditInstitution"
              label="¿De qué financiera vienen?"
              name="previousCreditInsitution"
            />
            <base-file-input
              v-model="formValues.paymentSheetFile"
              :value="paymentSheetUrl"
              label="Sube las hojas de pago"
              description="Esto en caso de que no estén en su historial de crédito"
              name="paymentSheet"
            />
          </div>
          <base-long-text-input
            v-model="formValues.additionalInformation"
            label="Información adicional"
            name="additionalInformation"
            placeholder="Con quiénes has trabajado, hace cuánto tiempo, cómo pagan, etc."
            class="text-left"
          />
        </base-form-section>
      </v-form>
    </template>
  </base-modal>
</template>

