<script setup lang="ts">
import { ArrowPathRoundedSquareIcon, TrashIcon, ClockIcon } from '@heroicons/vue/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { ref, computed } from 'vue';

import { type GroupLoanApplication } from '@/api/groupLoanApplication';
import {
  useGenerateUnsignedContracts,
  useUpdateGroupLoanApplication,
} from '@/api/groupLoanApplication/reviews/mutations';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseListBox from '@/components/base-list-box.vue';
import BaseModal from '@/components/base-modal.vue';
import GroupLoanApplicationSummary from '@/components/reviews/group-loan-application-summary.vue';
import useUIStore from '@/stores/ui';

type ListBoxAasmStates = 'inviting' | 'draft' | 'application_in_review' | 'in_kyc';
interface FireAasmStateUpdatePayload {
  aasmStateEvent: 'start_kyc'
  | 'accept_kyc'
  | 'pass_application_to_review'
  | 'reject_application'
  | 'review_kyc'
  | 'disburse';
}

const props = defineProps<{
  groupLoanApplication: GroupLoanApplication;
  previousGroupLoanApplications: GroupLoanApplication[];
}>();

const group = computed(() => props.groupLoanApplication.group);

const uiStore = useUIStore();

const groupLoanApplicationBadgeColor = computed(() => {
  switch (props.groupLoanApplication.aasmState) {
  case 'inviting':
    return 'blue';
  case 'draft':
    return 'yellow';
  case 'application_rejected':
    return 'red';
  default:
    return 'gray';
  }
});

const memberLoanApplicationsInKyc = computed(() => props.groupLoanApplication.memberLoanApplications.filter(
  (memberLoanApplication) => ['kyc_submitted', 'kyc_in_review'].includes(memberLoanApplication.aasmState),
));

const areAllContractsSentToGenerate = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => memberLoanApplication.unsignedContractGenerateJobStatus !== null,
));
const areAllContractsGenerated = computed(() => memberLoanApplicationsInKyc.value.every(
  (memberLoanApplication) => !!memberLoanApplication.unsignedContractUrl,
));

const updateGroupLoanApplicationAasmStateMutation = useUpdateGroupLoanApplication<{
  aasmState: ListBoxAasmStates
}>({
  id: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Solicitud actualizada',
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const fireAasmStateEventMutation = useUpdateGroupLoanApplication<FireAasmStateUpdatePayload>({
  id: props.groupLoanApplication.id,
  onSuccess: (_, { aasmStateEvent }) => {
    const message = {
      'start_kyc': 'Solicitud enviada a KYC',
      'accept_kyc': 'KYC aprobado',
      'pass_application_to_review': 'Solicitud enviada a revisión',
      'reject_application': 'Solicitud rechazada',
      'review_kyc': 'KYC pasado a revisión',
      'restart_application': 'Solicitud iniciada',
      'disburse': 'Crédito marcado como desembolsado',
    }[aasmStateEvent];

    uiStore.toast({
      message,
      type: 'success',
      position: 'top',
    });
  },
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al editar la solicitud',
      type: 'error',
      position: 'top',
    });
  },
});

const {
  mutate: generateUnsignedContracts, isPending: isGenerateUnsignedContractsPending,
} = useGenerateUnsignedContracts({
  groupLoanApplicationId: props.groupLoanApplication.id,
  onSuccess: () => {
    uiStore.toast({
      message: 'Contratos enviados a generar correctamente.',
      type: 'success',
      position: 'top',
    });
  },
  onError: () => {
    uiStore.toast({
      message: 'Hubo un error al generar los contratos',
      type: 'error',
      position: 'top',
    });
  },
});

const listBoxOptions = [
  { name: 'Invitaciones', value: 'inviting' },
  { name: 'Draft', value: 'draft' },
  { name: 'Review', value: 'application_in_review' },
  { name: 'KYC', value: 'in_kyc' },
];

const currentListBoxOption = computed(() => listBoxOptions.find(
  (option) => option.value === props.groupLoanApplication.aasmState),
);

const rejectApplicationConfirmationModalOpen = ref(false);
function rejectApplication() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'reject_application' });
  rejectApplicationConfirmationModalOpen.value = false;
}

const passApplicationToReviewConfirmationModalOpen = ref(false);
function passApplicationToReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'pass_application_to_review' });
  passApplicationToReviewConfirmationModalOpen.value = false;
}

const passApplicationToKycInReviewConfirmationModalOpen = ref(false);
function passApplicationToKycInReview() {
  fireAasmStateEventMutation.mutate({ aasmStateEvent: 'review_kyc' });
  passApplicationToKycInReviewConfirmationModalOpen.value = false;
}
</script>

<template>
  <div class="flex w-full flex-col gap-y-4">
    <div class="flex flex-row items-start justify-between">
      <div>
        <h1 class="text-lg">
          <span class="font-bold">{{ group.name }}</span> ({{ group.publicId }})
        </h1>
        <div className="flex gap-x-2 items-center">
          <p>Solicitud {{ groupLoanApplication.id }}</p>
          <div
            v-if="groupLoanApplication.submittedAt"
            class="flex flex-row items-center gap-x-1"
          >
            <p class="text-gray-700">
              - enviada
              {{ formatDistanceToNow(new Date(groupLoanApplication.submittedAt), { addSuffix: true }) }}
            </p>
            <ClockIcon
              class="h-4 w-4 text-primary-600/70"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center gap-x-4">
        <base-badge
          v-if="group.promoter"
          :label="group.promoter.fullName"
          color="gray"
          class="self-center"
        />
        <BaseBadge
          v-if="areAllContractsGenerated && groupLoanApplication.aasmState === 'kyc_in_review'"
          label="Contratos generados"
        />
        <base-list-box
          v-if="currentListBoxOption"
          :key="currentListBoxOption.value"
          class="w-44 rounded-md border border-gray-200"
          :options="listBoxOptions"
          :initial-selected="currentListBoxOption"
          :loading="updateGroupLoanApplicationAasmStateMutation.isPending"
          @update:model-value="(option) => updateGroupLoanApplicationAasmStateMutation.mutate(
            { aasmState: option.value }
          )"
        />
        <base-badge
          v-else
          :label="$t(`groupLoanApplication.aasmState.${groupLoanApplication.aasmState}`)"
          :color="groupLoanApplicationBadgeColor"
        />
        <button
          v-if="[
            'inviting',
            'draft',
            'application_in_review',
            'in_kyc',
            'kyc_in_review'
          ].includes(groupLoanApplication.aasmState)"
          class="flex items-center justify-center rounded-lg bg-red-500 p-2"
          @click="rejectApplicationConfirmationModalOpen = true"
        >
          <TrashIcon
            class="h-4 w-4 shrink-0 stroke-white"
          />
        </button>
      </div>
    </div>
    <div
      v-if="groupLoanApplication.cycle > 1"
      class="flex flex-col gap-y-2"
    >
      <div class="flex flex-row items-center gap-x-1">
        <ArrowPathRoundedSquareIcon class="h-4 w-4" />
        <span class="text-sm">
          Renovación (ciclo {{ groupLoanApplication.cycle }})
        </span>
      </div>
      <ul class="flex flex-row items-center gap-x-2">
        <span>
          Ciclos anteriores:
        </span>
        <li
          v-for="prevGroupLoanApplication in previousGroupLoanApplications"
          :key="prevGroupLoanApplication.id"
          class="mt-px"
        >
          <a
            :href="`/reviews/group_loan_applications/${prevGroupLoanApplication.id}`"
            target="_blank"
            class="text-blue-400 hover:opacity-75"
          >
            {{ prevGroupLoanApplication.cycle }}
          </a>
        </li>
      </ul>
    </div>
    <span>
      <b>nro presidenta: </b>
      <a
        :href="`https://wa.me/${group.president.phoneNumber}`"
        class="text-blue-500"
        target="_blank"
      >
        {{ group.president.phoneNumber }}
      </a>
    </span>
    <div class="flex flex-row items-center gap-x-2">
      <base-button
        v-if="groupLoanApplication.aasmState === 'draft'"
        text="Pasar a revisión"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => passApplicationToReviewConfirmationModalOpen = true"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'application_in_review'"
        text="Pasar a KYC"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'start_kyc' })"
      />
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'in_kyc'"
        text="Pasar KYC a revisión"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="passApplicationToKycInReviewConfirmationModalOpen = true"
      />
      <template v-else-if="groupLoanApplication.aasmState === 'kyc_in_review'">
        <base-button
          v-if="areAllContractsGenerated"
          text="Aprobar KYC"
          :loading="fireAasmStateEventMutation.isPending"
          class="self-start"
          @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'accept_kyc' })"
        />
        <base-button
          v-else
          :text="areAllContractsSentToGenerate ? 'Generando contratos' : 'Generar contratos'"
          :disabled="areAllContractsSentToGenerate"
          :loading="isGenerateUnsignedContractsPending"
          class="self-start"
          @click="generateUnsignedContracts"
        />
      </template>
      <base-button
        v-else-if="groupLoanApplication.aasmState === 'contracts_signed'"
        text="Marcar como desembolsado"
        class="self-start"
        :loading="fireAasmStateEventMutation.isPending"
        @click="fireAasmStateEventMutation.mutate({ aasmStateEvent: 'disburse' })"
      />
      <base-button
        v-if="'application_rejected' === groupLoanApplication.aasmState"
        text="Revivir solicitud"
        class="self-start"
        @click="() => fireAasmStateEventMutation.mutate({ aasmStateEvent: 'restart_application' })"
      />
    </div>
    <div class="mt-8 px-4">
      <group-loan-application-summary
        :group-loan-application="groupLoanApplication"
        :group-members="group.members"
      />
    </div>
  </div>
  <base-modal
    :show="passApplicationToReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    accept-button-text="Sí, pasar a revisión"
    cancel-button-text="Cancelar"
    :body="group.pendingInvitations.length > 0
      ? `Hay ${group.pendingInvitations.length} invitaciones pendientes. ¿Estás seguro que deseas pasar a revisión?`
      : null"
    @accept="passApplicationToReview"
    @close="passApplicationToReviewConfirmationModalOpen = false"
  />
  <base-modal
    :show="passApplicationToKycInReviewConfirmationModalOpen"
    title="¿Estás seguro?"
    body="Los integrantes que no hayan subido sus documentos serán desactivados."
    accept-button-text="Sí, pasar KYC a revisión"
    cancel-button-text="Cancelar"
    @accept="passApplicationToKycInReview"
    @close="passApplicationToKycInReviewConfirmationModalOpen = false"
  />
  <base-modal
    :show="rejectApplicationConfirmationModalOpen"
    title="Rechazar solicitud"
    accept-button-text="Rechazar"
    accept-button-variant="red"
    cancel-button-text="Cancelar"
    @accept="rejectApplication"
    @close="rejectApplicationConfirmationModalOpen = false"
  />
</template>
